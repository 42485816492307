let ThirtyFiveSeries =() =>{

    return <pre>

        {`
35G	Signals Intelligence/Electronic Warfare (SIGINT/EW)	                        Officer
35B	Strategic Intelligence	                                                    Officer
35C	Imagery Intelligence (IMINT)	                                            Officer
35D	All Source Intelligence	                                                    Officer
35E	Counter Intelligence	                                                    Officer
35F	Human Intelligence (HUMINT)	                                                Officer
35A	Land Combat Electronic Missile System Repairer								Enlisted
35B	Electronic Instrument Repairman	                                            Enlisted
35B	Land Combat Support Systems (LCSS) Test Specialist							Enlisted
35C	Surveillance Radar Repairer													Enlisted
35D	Air Traffic Control Equipment Repairer										Enlisted
35E	Radio and Communications Security (COMSEC) Repairer							Enlisted
35F	Intelligence Analyst													    Enlisted
35F	Special Electronic Devices Repairer                                			Enlisted
35G	Imagery Analyst	                                                            Enlisted
35G	Geospatial Intelligence Imagery Analyst	                                    Enlisted
35H	Common Ground Station (CGS) Analyst                   						Enlisted
35H	Test, Measurement, and Diagnostic Equipment (TMDE) Maintenance Sup			Enlisted
35J	Computer/Automation Systems Repair											Enlisted
35K	Apache Attack Helicopter Systems Repairer									Enlisted
35K	Unmanned Aerial Vehicle Operator											Enlisted
35L	Avionic Communications Equipment											Enlisted
35L	Counter Intelligence Agent													Enlisted
35M	Radar Repairer													            Enlisted
35M	Human Intelligence Collector												Enlisted
35N	Wire Systems Equipment Repairer												Enlisted
35N	Signals Intelligence Analyst												Enlisted
35P	Multiple Launch Rocket System (MLRS) Repairer								Enlisted
35P	Cryptologic Linguist (Crypto Linguist)										Enlisted
35Q	Avionic Flight Systems Repairer												Enlisted
35Q	Cryptologic Network Warfare Specialist										Enlisted
35R	Avionic System Repairer													    Enlisted
35S	PATRIOT System Repairer													    Enlisted
35S	Signals Collector/Analyst													Enlisted

35T	Avenger System Repairer													    Enlisted
35T	Military Intelligence (MI) Systems Maintainer/Integrator					Enlisted
35U	Biomedical Equipment Specialist												Enlisted
35U	Multi Sensor Operator													    Enlisted
35V	Signals Intelligence													    Enlisted
35V	Electronic and Missile Systems Maintenance Chief							Enlisted
35W	Electronic Maintenance Chief												Enlisted
35X	Intelligence Senior Sergeant/Chief Intelligence Sergeant					Enlisted
35X	Intelligence Senior Sergeant/Chief Intelligence Sergeant					Enlisted
35Y	Integrated Family of Test Equipment Operator/Maintainer						Enlisted
35Y	Chief Counter Intelligence/Human Intelligence Sergeant						Enlisted
35Z	Senior Electronic Maintenance Chief											Enlisted
35Z	Signals Intelligence (Electronic Warfare)/ Senior Sergeant/Chief			Enlisted
        




https://mosdb.com/army/



        `}
    </pre>
}

export default ThirtyFiveSeries;

