import "./App.css";
import React from "react";
import Navigation from "./components/Navigation";
import { Tab, Col, Row } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import ThirtyFiveSeries from "./35";
import "./tutorials.scss";
import useSound from 'use-sound';
import PlayButton from './components/PlayButton';
import styles from './components/PlayPause.css';

function PlayPause(s) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [play, { stop }] = useSound(s.s, {
    onend: () => setIsPlaying(false),
  });

  return (
    <PlayButton
      className={styles.btn}
      active={isPlaying}
      size={60}
      iconColor="hsl(0deg 0% 100%)"
      idleBackgroundColor="hsl(210deg 15% 6%)"
      activeBackgroundColor="hsl(240deg 95% 62%)"
      play={() => {
        play();
        setIsPlaying(true);
      }}
      stop={() => {
        stop();
        setIsPlaying(false);
      }}
    />
  );
}

function TutorialsTabs() {
  return (
    <div>
      <Tab.Container id='list-group-tabs-example' defaultActiveKey='#link1'>
        <Row>
          <Col xs={2} md={2} lg={2} xl={2} xxl={2}>
            <Sidebar />
          </Col>

          <Col sm={10} xs={10} md={10} lg={10} xl={10} xxl={10}>
            <div className='high-speed-content'>
              <h1>Highspeed stuff</h1>
             
              <a
                target='_blank'
                rel='noreferrer'
                href='https://intelshare.intelink.gov/sites/usarwarriorfitt/SitePages/Home.aspx'
              >
                https://intelshare.intelink.gov/sites/usarwarriorfitt/SitePages/Home.aspx
              </a>
              <br />
              <a
                target='_blank'
                rel='noreferrer'
                href='https://mbf.hrc.army.mil/'
              >
                Army Selection Board System
              </a>
              <h4>SERGEANT AUDIE MURPHY BOARD</h4>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.army.mil/article/222810/2idrucd_soldiers_take_the_sgt_audie_murphy_club_challenge'
              >
                https://www.army.mil/article/222810/2idrucd_soldiers_take_the_sgt_audie_murphy_club_challenge
              </a>
              <br />
              <a
                target='_blank'
                rel='noreferrer'
                href='https://quizlet.com/256359658/audie-murphy-board-questions-flash-cards/'
              >
                https://quizlet.com/256359658/audie-murphy-board-questions-flash-cards/
              </a>
              <br />
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.army.mil/bestsquad/index.html'
              >
                https://www.army.mil/bestsquad/index.html
              </a>
              <br />
              <br />
              <br />
              <div>
                <h4>German Armed Forces Badge for Military Proficiency</h4>
              </div>
              https://www.youtube.com/watch?v=h3fB1uykvRc&ab_channel=Newsdividshub
              <br />
              <a
                href='https://www.aviano.af.mil/Portals/1/documents/Wyvern/GAFPB.pdf?ver=2016-09-19-045529-400'
                target='_blank'
                rel='noreferrer'
              >
                PDF
              </a>
              <br />
              <p>
                90 minute timer 11 x 10-meter sprint test Chin-up test
                (flexed-arm hang) Minimum pass Requirements 5 sec 1000 m Run
                Minimum pass Requirements max. 6min 30 sec ESB
              </p>
              <br />
              <br />
              https://usacimt.tradoc.army.mil/ltb/esb/esb-tasks.html
              <br />
              <Tab.Content>
                <Tab.Pane eventKey='#link1'>
                  <a
                    className='inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-1050 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong'
                    data-twe-collapse-init
                    data-twe-ripple-init
                    data-twe-ripple-color='light'
                    href='#armyvalues'
                    role='button'
                    aria-expanded='false'
                    aria-controls='armyvalues'
                  >
                    Army Values
                  </a>

                  <button
                    className='inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong'
                    type='button'
                    data-twe-collapse-init
                    data-twe-ripple-init
                    data-twe-ripple-color='light'
                    data-twe-target='#soldierscreed'
                    aria-expanded='false'
                    aria-controls='soldierscreed'
                  >
                    Soldier's Creed
                  </button>

                  <a
                    className='inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong'
                    data-twe-collapse-init
                    data-twe-ripple-init
                    data-twe-ripple-color='light'
                    href='#buglecalls'
                    role='button'
                    aria-expanded='false'
                    aria-controls='buglecalls'
                  >
                    Bugle Calls
                  </a>

                  <div
                    
                    id='armyvalues'
                    data-twe-collapse-item
                  >
                    <h4>Army Values</h4>
                    <ul>
                      <li>Loyalty</li>
                      <li>Duty</li>
                      <li>Respect</li>
                      <li>Selfless Service</li>
                      <li>Honor</li>
                      <li>Integrity</li>
                      <li>Personal Courage</li>
                    </ul>
                  </div>
                  <div
                    
                    id='soldierscreed'
                    data-twe-collapse-item
                  >
                    <h5>Soldier's Creed</h5>
                    <p>I am an American Soldier.</p>
                    <p>I am a warrior and a member of a team.</p>
                    <p>
                      I serve the people of the United States and live the Army
                      Values.
                    </p>
                    <p>I will always place the mission first.</p>
                    <p>I will never accept defeat.</p>
                    <p>I will never quit.</p>
                    <p>I will never leave a fallen comrade.</p>
                    <p>
                      I am disciplined, physically and mentally tough, trained
                      and proficient in my Warrior tasks and drills.
                    </p>
                    <p>I always maintain my arms, my equipment and myself.</p>
                    <p>I am an expert and I am a professional.</p>
                    <p>
                      I stand ready to deploy, engage, and destroy the enemies
                      of the United States of America in close combat.
                    </p>
                    <p>
                      I am a guardian of freedom and the American way of life.
                    </p>
                    <p>I am an American Soldier.</p>
                  </div>

                  <div
                    
                    id='buglecalls'
                    data-twe-collapse-item
                  >
                    <h5> Army Bugle Call</h5>
                    <p>
                      Happens on military installations. There are two major
                      bugle calls that happenduring day, that requires attent
                      ion. (Times can be slightly different according to the
                      base rules.)
                    </p>
                    <ol>
                      <li> 0625 : First Call. <PlayPause s="./sounds/FirstCall.mp3" /></li>
                      <li> 0630 : Reveille  <PlayPause s="./sounds/reveille.mp3" /></li>
                      <li> Taps  <PlayPause s="./sounds/Taps.mp3" /></li>
                      <li> Tattoo  <PlayPause s="./sounds/tattoo.mp3" /></li>
                      <li> To the Color  <PlayPause s="./sounds/tothecolor.mp3" /></li>
                      <li> Retreat <PlayPause s="./sounds/retreat.mp3" /></li>
                    </ol>

                    <div>
                      https://home.army.mil/stewart/my-fort/soldiers/bugle-calls
                    </div>
                  </div>
                  <div>
                    <br />
                    <h5>References</h5>
                    <ol>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://usacimt.tradoc.army.mil/assets/pdf/tp600-4.pdf'
                        >
                          TP 600-4 Army Blue Book
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://armypubs.army.mil/epubs/DR_pubs/DR_a/ARN30302-AR_670-1-000-WEB-1.pdf'
                        >
                          AR 670-1 Wear and Appearance of Army Uniforms and
                          Insignia
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://www.brunton.com/blogs/blog/how-to-use-a-lensatic-compass'
                        >
                          https://www.brunton.com/blogs/blog/how-to-use-a-lensatic-compass
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://www.army.mil/article/45902/the_armys_foundational_building_blocks_of_soldier_skills'
                        >
                          https://www.army.mil/article/45902/the_armys_foundational_building_blocks_of_soldier_skills
                        </a>
                      </li>
                    </ol>
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <ThirtyFiveSeries />
              <table className='regulations'>
                <tbody>
                  <tr>
                    <th>Regulation Name</th>
                    <th>Description</th>
                    <th>No. of Pages</th>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.moore.army.mil/Infantry/199th/2-16/ABOLC/content/pdf/Salutes,%20Honors,%20and%20Courtesy.pdf?17JUN2020'
                      >
                        {" "}
                        AR 600–25
                      </a>
                    </td>
                    <td> Salutes, Honors, and Courtesy</td>
                    <td>29</td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.armywriter.com/p623_3.pdf'
                      >
                        AR 623-3
                      </a>
                    </td>
                    <td>Evaluation Reporting System</td>
                    <td>103</td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://armypubs.army.mil/epubs/DR_pubs/DR_a/ARN30964-FM_7-22-001-WEB-4.pdf'
                      >
                        FM 7-22
                      </a>
                    </td>
                    <td>Holistic Health and Fitness</td>
                    <td>244</td>
                  </tr>
                </tbody>
              </table>
              <pre>
                {`
      q: What is counselling

      Performace and potential

      3 major counselling

      Event, Performace and professional growth


      What are 7 examples of event oriented counselling
      1. Superior/substandard counselling



`}
              </pre>
              <br />
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

const Tutorials = () => {
  return (
    <>
      <Navigation />

      <div className='big-container'>
        <TutorialsTabs />
      </div>
    </>
  );
};
export default Tutorials;
