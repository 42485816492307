import React, { useState } from "react";
import "./sidebar.scss";
import highspeed from "../highspeed.svg";
import { Col } from "react-bootstrap";
/* https://codepen.io/StephenScaff/pen/bVbEbJ  */
function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <aside className='sidebar'>
        <header>
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? "Close" : "Open"} Sidebar
          </button>
        </header>
        <nav className='sidebar-nav'>
          <ul>
            <li>
              <a href='#'>
                <img src={highspeed} className='highspeed' /> <span className="label">Highspeed</span>
              </a>
              <ul className='nav-flyout'>
                <li>
                  <a href='#'>
                    <i className='ion-ios-color-filter-outline' />
                    Derps
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-clock-outline' />
                    Times
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-android-star-outline' />
                    Hates
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-heart-broken' />
                    Beat
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#'>
                <i className='ion-ios-settings' />{" "}
                <span className='label'>Controls</span>
              </a>
              <ul className='nav-flyout'>
                <li>
                  <a href='#'>
                    <i className='ion-ios-alarm-outline' />
                    <span className="label"> Watch</span>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-camera-outline' />
                    <span className="label">Creeper</span>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-chatboxes-outline' />
                    <span className="label">Hate</span>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-cog-outline' />
                    Grinder
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#'>
                <i className='ion-ios-briefcase-outline' />
                <span className='label'>Folio</span>
              </a>
              <ul className='nav-flyout'>
                <li>
                  <a href='#'>
                    <i className='ion-bag' />
                    <span className="label">Highspeed</span>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-lightbulb-outline' />
                    Bulbs
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-location-outline' />
                    Where You
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-locked-outline' />
                    On Lock
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-navigate-outline' />
                    Ghostface
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#'>
                <i className='ion-ios-analytics-outline' />{" "}
                <span className='label'>Graphicals</span>
              </a>
              <ul className='nav-flyout'>
                <li>
                  <a href='#'>
                    <i className='ion-ios-timer-outline' />
                    Timers
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-arrow-graph-down-left' />
                    You Lose
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-partlysunny-outline' />
                    Stormy
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-timer-outline' />
                    Lookie Look
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-game-controller-a-outline' />
                    Dork Mfer
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#'>
                <i className='ion-ios-paper-outline' />{" "}
                <span className='label'>Papers</span>
              </a>
              <ul className='nav-flyout'>
                <li>
                  <a href='#'>
                    <i className='ion-ios-filing-outline' />
                    File Cab
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-information-outline' />
                    Infos
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-paperplane-outline' />
                    Planes
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-android-star-outline' />
                    Shop
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#'>
                <i className='ion-ios-navigate-outline' />{" "}
                <span className='label'>Ass Finder</span>
              </a>
              <ul className='nav-flyout'>
                <li>
                  <a href='#'>
                    <img src={highspeed} className='highspeed' />
                    Highspeed
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-lightbulb-outline' />
                    Bulbs
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-location-outline' />
                    Where You
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-locked-outline' />
                    On Lock
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='ion-ios-navigate-outline' />
                    Ghostface
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#'>
                <i className='ion-ios-medical-outline' />{" "}
                <span className='label'>Cocaine</span>
              </a>
            </li>
          </ul>
        </nav>
      </aside>
    </>
  );
}

export default Sidebar;
